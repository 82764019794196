import React, { useState, useCallback, useEffect } from "react"
import { ChoiceList, Card, Stack, Modal, TextContainer } from "@shopify/polaris"

export const ProductModal = props => {
  const { product, active, setActive, products, onChangeTags } = props

  const [selectedOptions, setSelectedOptions] = useState(product.tags || [])

  const handleModalChange = useCallback(() => setActive(!active), [active, setActive])
  const handleSelectedOptionsChange = useCallback(value => setSelectedOptions(value), [])

  const handleClose = () => {
    handleModalChange()
    handleSelectedOptionsChange(product.tags)
  }

  const handleUpdate = () => {
    const current = products.find(p => p.id === product.id) || product
    const tags = current.tags.filter(t => !t.includes(`payment-plan:`)).concat(selectedOptions)

    onChangeTags(current.id, tags)
  }

  useEffect(() => {
    handleSelectedOptionsChange(product.tags)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const termOptions = [
    { label: "6 months", value: "payment-plan:6" },
    { label: "4 months", value: "payment-plan:4" },
    { label: "2 months", value: "payment-plan:2" },
  ]

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title="Add/Update Product Eligibility"
      primaryAction={{
        content: "Update",
        onAction: handleUpdate,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Card>
        <Card.Header title={product?.title && product.title !== "" ? product.title : "No Title"}></Card.Header>
        <Card.Section>
          <TextContainer>{product?.description && product.description !== "" ? product.description : "No description"}</TextContainer>
        </Card.Section>
        <Card.Section title="Available Options" subdued>
          <Stack vertical>
            <Stack.Item>
              <ChoiceList allowMultiple choices={termOptions} selected={selectedOptions} onChange={handleSelectedOptionsChange} />
            </Stack.Item>
          </Stack>
        </Card.Section>
        <Card.Section title="Note">
          <TextContainer>
            {`The available options are determined by the product as only a few will be available for the longest running payment plans. This will be
            handled on the product-level with tags in the Shopify dashboard.`}
          </TextContainer>
        </Card.Section>
      </Card>
    </Modal>
  )
}
