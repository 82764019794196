import React from "react"
import { Pagination } from "@shopify/polaris"

export const ProductPagination = props => {
  const { showPrev, showNext, onPrevious, onNext } = props

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Pagination hasPrevious={showPrev} onPrevious={onPrevious} hasNext={showNext} onNext={onNext} />
    </div>
  )
}
