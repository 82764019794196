import React, { useCallback } from "react"
import { ChoiceList } from "@shopify/polaris"

export const ProductFilter = props => {
  const { selected, onSelected } = props

  const handleChange = useCallback(value => onSelected(value), [onSelected])

  return (
    <ChoiceList
      allowMultiple
      choices={[
        {
          label: "2 months",
          value: "payment-plan:2",
        },
        {
          label: "4 months",
          value: "payment-plan:4",
        },
        {
          label: "6 months",
          value: "payment-plan:6",
        },
      ]}
      selected={selected}
      onChange={handleChange}
    />
  )
}
