import React, { useState, useCallback, useEffect } from "react"
import { Layout, Card, Filters, ResourceList, Badge, TextStyle, ResourceItem, Button, TextContainer, Modal } from "@shopify/polaris"
import { ResourcePicker } from "@shopify/app-bridge-react"
import { useLazyQuery, useMutation } from "react-apollo"
import { GET_PRODUCTS, UPDATE_PRODUCT_TAGS } from "helpers/graphql"

import { ProductFilter } from "./ProductFilter"
import { ProductModal } from "./ProductModal"
import { ProductPagination } from "./ProductPagination"

const DEFAULT_QUERY_STRING = `tag:'payment-plan:'`

export const ProductList = props => {
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [firstCursor, setFirstCursor] = useState(null)
  const [lastCursor, setLastCursor] = useState(null)
  const [showPrev, setPrev] = useState(false)
  const [showNext, setNext] = useState(false)
  const [query, setQuery] = useState(DEFAULT_QUERY_STRING)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [modalActive, setModalActive] = useState(false)
  const [removeModalActive, setRemoveModalActive] = useState(false)
  const [resourcePickerActive, setResourcePickerActive] = useState(false)
  const [sortValue, setSortValue] = useState("DATE_MODIFIED_DESC")
  const [taggedWith, setTaggedWith] = useState([])
  const [queryValue, setQueryValue] = useState(null)
  const [getProducts, { data: queryProductsData }] = useLazyQuery(GET_PRODUCTS, {
    variables: { first: 10, query, reverse: sortValue !== "DATE_MODIFIED_DESC" },
    fetchPolicy: "no-cache",
  })
  const [updateProductTags, { data: mutateProductTagsData }] = useMutation(UPDATE_PRODUCT_TAGS)

  const handleOpenResourcePicker = useCallback(() => setResourcePickerActive(true), [])
  const handleCloseResourcePicker = useCallback(() => setResourcePickerActive(false), [])
  const handleTaggedWithChange = useCallback(value => setTaggedWith(value), [])
  const handleQueryValueChange = useCallback(value => setQueryValue(value), [])
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), [])
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), [])
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove()
    handleQueryValueRemove()
  }, [handleQueryValueRemove, handleTaggedWithRemove])

  useEffect(() => {
    getProducts()
  }, [getProducts])

  useEffect(() => {
    let query = taggedWith.length ? taggedWith.map(tag => `tag:'${tag}'`).join(" AND ") : DEFAULT_QUERY_STRING
    if (queryValue && queryValue !== "") {
      query += ` AND title:${queryValue}*`
    }
    setQuery(query)
  }, [taggedWith, queryValue])

  useEffect(() => {
    if (!queryProductsData) return
    const { products } = queryProductsData
    setProducts(products.edges.map(edge => edge.node))
    handleData(queryProductsData)
  }, [queryProductsData])

  useEffect(() => {
    if (!mutateProductTagsData) return
    setModalActive(false)
    setRemoveModalActive(false)
    getProducts({ fetchPolicy: "no-cache" })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateProductTagsData])

  const handleData = queryProductsData => {
    const { products } = queryProductsData
    setPrev(products?.pageInfo?.hasPreviousPage)
    setNext(products?.pageInfo?.hasNextPage)
    setFirstCursor(products?.edges && products?.edges.length ? products.edges[0].cursor : null)
    setLastCursor(products?.edges && products?.edges.length ? products.edges[products.edges.length - 1].cursor : null)
    setLoading(false)
  }

  const handleUpdateProductTags = (productId, tags) => {
    updateProductTags({
      variables: {
        input: {
          id: productId,
          tags: tags.join(","),
        },
      },
    })
  }

  const handleCloseModal = () => {
    setSelectedProduct(null)
    setModalActive(false)
    setRemoveModalActive(false)
  }

  const handleRemoveProductTags = () => {
    setLoading(true)
    const found = products.find(p => p.id === selectedProduct.id)
    const tags = found.tags.filter(t => !t.includes(`payment-plan:`))
    handleUpdateProductTags(found.id, tags)
  }

  const handleClickRemoveProductTags = product => {
    setSelectedProduct(product)
    setRemoveModalActive(true)
  }

  const handleClickEditTerms = product => {
    setSelectedProduct(product)
    setModalActive(true)
  }

  const handleClickPrevious = () => {
    setLoading(true)
    getProducts({
      variables: { first: null, last: 10, query, beforeCursor: firstCursor, reverse: sortValue !== "DATE_MODIFIED_DESC" },
      fetchPolicy: "no-cache",
    })
  }

  const handleClickNext = () => {
    setLoading(true)
    getProducts({
      variables: { first: 10, last: null, query, afterCursor: lastCursor, reverse: sortValue !== "DATE_MODIFIED_DESC" },
      fetchPolicy: "no-cache",
    })
  }

  const handleAddProduct = selectPayload => {
    setSelectedProduct(selectPayload.selection[0])
    setResourcePickerActive(false)
    setModalActive(true)
  }

  const resourceName = {
    singular: "product",
    plural: "products",
  }

  const items = products
    ? products.map(item => ({
        id: item.id,
        title: item.title,
        tags: item.tags ? getTermTags(item.tags) : [],
        onlineStoreUrl: item.onlineStoreUrl,
      }))
    : []

  const filters = [
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: <ProductFilter selected={taggedWith} onSelected={handleTaggedWithChange} />,
      shortcut: true,
    },
  ]

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: "taggedWith",
          label: disambiguateLabel("taggedWith", taggedWith),
          onRemove: handleTaggedWithRemove,
        },
      ]
    : []

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    >
      <div style={{ paddingLeft: "8px" }}>
        <Button primary onClick={handleOpenResourcePicker}>
          {`Add New`}
        </Button>
      </div>
    </Filters>
  )

  const renderItem = item => {
    const { id, title, tags } = item
    const shortcutActions = [
      {
        content: "Edit",
        onAction: () => handleClickEditTerms(item),
      },
      {
        content: "Remove",
        onAction: () => handleClickRemoveProductTags(item),
        destructive: true,
      },
    ]
    return (
      <ResourceItem key={id} id={id} shortcutActions={shortcutActions} persistActions>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "40%",
            }}
          >
            <h3>
              <TextStyle variation="strong">{title}</TextStyle>
            </h3>
          </div>
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "flex-end",
              textTransform: "capitalize",
            }}
          >
            {tags.map(term => (
              <Badge status={`info`}>{term}</Badge>
            ))}
          </div>
        </div>
      </ResourceItem>
    )
  }

  return (
    <>
      <Layout>
        <Layout.Section>
          <Card>
            <ResourceList
              loading={loading}
              resourceName={resourceName}
              items={items}
              renderItem={renderItem}
              sortValue={sortValue}
              sortOptions={[
                { label: "Newest update", value: "DATE_MODIFIED_DESC" },
                { label: "Oldest update", value: "DATE_MODIFIED_ASC" },
              ]}
              onSortChange={selected => {
                setSortValue(selected)
              }}
              filterControl={filterControl}
            />
          </Card>
          <br />
        </Layout.Section>
      </Layout>
      <ProductPagination showPrev={!loading && showPrev} showNext={!loading && showNext} onPrevious={handleClickPrevious} onNext={handleClickNext} />
      {selectedProduct && (
        <ProductModal
          active={modalActive}
          setActive={setModalActive}
          product={selectedProduct}
          products={products}
          onChangeTags={handleUpdateProductTags}
        />
      )}

      <ResourcePicker
        resourceType="Product"
        open={resourcePickerActive}
        onCancel={handleCloseResourcePicker}
        onSelection={handleAddProduct}
        showVariants={false}
        allowMultiple={false}
      />

      <Modal
        open={removeModalActive}
        onClose={handleCloseModal}
        title="Remove Eligible Product?"
        primaryAction={{
          content: "Delete",
          onAction: handleRemoveProductTags,
          destructive: true,
          loading: loading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{`All payment plan tags will be removed from the product.`}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  )
}

const getTermTags = tags => {
  return tags.filter(tag => tag.includes("payment-plan:"))
}

const disambiguateLabel = (key, value) => {
  switch (key) {
    case "taggedWith":
      return `Tagged with ${value}`
    default:
      return value
  }
}

const isEmpty = value => {
  if (Array.isArray(value)) {
    return value.length === 0
  } else {
    return value === "" || value == null
  }
}
